import React, { useEffect, useState } from "react";
import { Link } from "react-router-dom";
import { get } from "lodash";
import { connect } from "react-redux";

import { getMarketplaceRequest } from "../actions";

import Header from "../components/header/Header";
import Footer from "../components/footer/Footer";
import {  formatWalletHash } from "../utils/transformers";
// import PageLoader from "../components/loader/PageLoader";
import { TailSpin } from  'react-loader-spinner'
import ImageVideoPreview from "../components/comman/ImageVideoPreview";

const MintNftList = ({ marketList, getMarketplaceRequest, error, loading }) => {
  const [visible, setVisible] = useState(6);
  const [copySuccess, setCopySuccess] = useState(false);

  useEffect(() => {
    getMarketplaceRequest();
  }, [getMarketplaceRequest]);

  const showMoreItems = () => {
    setVisible((prevValue) => prevValue + 3);
  };

  return (
    <div>
      <Header />
      <section className="flat-title-page inner">
        <div className="overlay"></div>
        <div className="themesflat-container">
          <div className="row">
            <div className="col-md-12">
              <div className="page-title-heading mg-bt-12">
                <h1 className="heading text-center">Listings</h1>
              </div>
              <div className="breadcrumbs style2">
                <ul>
                  <li>
                    <Link to="/">Home</Link>
                  </li>
                  <li>Listings</li>
                </ul>
              </div>
            </div>
          </div>
        </div>
      </section>
      <section className="tf-section tf-rank tf-section-mint-nft-list">
        <div className="container-fluid">
          <div className="row">
            <div className="col-md-12">
              <div className="table-ranking">
                <div className="flex th-title item nft-list">
                  <div className="column">
                    <h3>ID</h3>
                  </div>
                  <div className="column">
                    <h3>IMAGE</h3>
                  </div>
                  <div className="column">
                    <h3>NAME</h3>
                  </div>
                  <div className="column">
                    <h3>SELLER</h3>
                  </div>
                  <div className="column">
                    <h3>COPIES</h3>
                  </div>
                  <div className="column">
                    <h3>PRICE</h3>
                  </div>
                </div>
                {loading ? (
                   <div className="react-loader">
                     <TailSpin color="#4A3CE5" height={80} width={80}/>
                    </div>
                ) : marketList.length > 0 ? (
                  marketList.slice(0, visible).map((item, index) => (
                    <Link
                      to={`/order-details/${item?.id}`}
                      key={index}
                      className="fl-item2 column-padding cursor-pointer"
                    >
                      <div className="fl-item2 column-padding ">
                        <div className="item flex">
                          <div className="column">
                            <p className="overflow-ellipsis">{item?.id}</p>
                          </div>
                          <div className="column">
                            <ImageVideoPreview
                                data={get(item, "asset", {})}
                                alt="whitelabel"
                              />
                          </div>
                          <div className="column overflow-ellipsis">
                            <span>{get(item, "asset.name", "")}</span>
                          </div>
                          <div className="column">
                            <div className="btn-rounded">
                              <i
                                onClick={(e) => {
                                  e.stopPropagation();
                                  navigator.clipboard.writeText(
                                    get(item, "creator", "")
                                  );
                                  setCopySuccess(index);
                                  setTimeout(() => setCopySuccess(-1), 1000);
                                }}
                                className={`fal fa-copy cursor-pointer ${
                                  copySuccess === index ? "copy-success" : ""
                                }`}
                              ></i>
                              <span className="copy-Text">
                                {formatWalletHash(
                                  get(item, "sellerAddress", "")
                                )}
                              </span>
                            </div>
                          </div>
                          <div className="column">
                            <span>
                              {parseInt(get(item, "quantity.hex"), 16)}
                            </span>
                          </div>
                          <div className="column overflow-ellipsis">
                            <span>
                              {get(
                                item,
                                "buyoutCurrencyValuePerToken.displayValue",
                                {}
                              )}
                            </span>
                          </div>
                        </div>
                      </div>
                    </Link>
                  ))
                ) : (
                  <h3 className="text-center">No NFT found</h3>
                )}
                {!loading && visible < marketList.length && (
                  <div className="col-md-12 wrap-inner load-more text-center">
                    <Link
                      to="#"
                      id="load-more"
                      className="sc-button loadmore fl-button pri-3"
                      onClick={showMoreItems}
                    >
                      <span>Load More</span>
                    </Link>
                  </div>
                )}
              </div>
            </div>
          </div>
        </div>
      </section>
      <Footer />
    </div>
  );
};

export default connect(
  ({ data }) => ({
    marketList: data.marketList,
    error: data.error,
    loading: data.loading,
  }),
  {
    getMarketplaceRequest,
  }
)(MintNftList);
