import { Types } from "../actions";

const initialState = {
  nftListItems: [],
  error: "",
  nftDetails: {},
  marketList: [],
  loading: false,
  orderHistoryList:[],
};

export const rootReducer = (state = initialState, action) => {
  switch (action.type) {
    case Types.GET_NFT_LIST:
      return {
        ...state,
        loading: true,
      };
    case Types.GET_NFT_LIST_SUCCESS:
      return {
        ...state,
        nftListItems: action.payload,
        loading: false,
      };
    case Types.SHOW_ERROR:
      return {
        ...initialState,
        error: action.error,
        loading: false,
      };
    case Types.SET_NFT_DETAILS:
      return {
        ...state,
        nftDetails: action.payload,
      };
    case Types.GET_MARKETPLACE_LIST:
      return {
        ...state,
        loading: true,
      };
    case Types.GET_MARKETPLACE_LIST_SUCCESS:
      return {
        ...state,
        marketList: action.payload,
        loading: false,
      };
    case Types.GET_NFT_HISTORY_LIST:
      return {
        ...state,
        loading: true,
      };
    case Types.GET_NFT_HISTORY_LIST_SUCCESS:
    return {
      ...state,
      orderHistoryList: action.payload,
      loading: false,
    };
    default:
      return state;
  }
};
