import axios from "axios";
import { create } from "ipfs-http-client";
import { Buffer } from "buffer";
export const getNFTList = () => {
  return axios.get("/getNFTList");
};

export const getMarketPlaceList = () => {
  return axios.get("/getMarketPlaceList");
};

export const getHistoryList = () => {
  return axios.get("/getMintList");
};

export const mintNFT = async (previewItem) => {
  const auth =
    'Basic ' + Buffer.from(process.env.REACT_APP_INFURA_PROJECT_ID + ':' + process.env.REACT_APP_INFURA_PROJECT_SECRET).toString('base64');
  const client = create({
    host: 'ipfs.infura.io',
    port: 5001,
    protocol: 'https',
    headers: {
      authorization: auth,
    },
  });
  let created;
  try{
    created = await client.add(previewItem?.file);

  }catch(err){
    console.log(err);
  }

  const properties =
    previewItem?.properties.length === 1
      ? ""
      : JSON.stringify(previewItem?.properties);

  const data = {
    "name": previewItem?.title,
    "description": previewItem?.description,
    "properties": properties,
    "ipfsHash": created?.path,
    "file": previewItem?.file,
    "copies": previewItem?.copies,
    "isImage": previewItem?.isImage
  };

  return axios({
    method: "post",
    url: "/mint",
    headers: {
      "content-type": "application/json",
    },
    data,
  });
};

export const createDirectList = async (props) => {
  const data = {
    "quantity": props?.quantity,
    "tokenId": props?.tokenId,
    "price": props?.price.toString(),
  };
  return axios({
    method: "post",
    url: "/createDirectListing",
    headers: {
      "content-type": "application/json",
    },
    data,
  });
};

export const createAuctionList = async (props) => {
  const data = {
    "quantity": props?.quantity.toString(),
    "tokenId": props?.tokenId,
    "price": props?.price.toString(),
    "floorPrice": props?.floorPrice.toString(),
    "duration": props?.duration.toString(),
  };

  return axios({
    method: "post",
    url: "/createAuctionListing",
    headers: {
      "content-type": "application/json",
    },
    data,
  });
};

export const cancelDirectListing = async (props) => {
  return axios({
    method: "post",
    url: "/cancelDirectListing",
    headers: {
      "content-type": "application/json",
    },
    data: {
      "listingId": props?.listingId,
    },
  });
};

export const cancelAuctionListing = async (props) => {
  return axios({
    method: "post",
    url: "/cancelAuctionListing",
    headers: {
      "content-type": "application/json",
    },
    data: {
      "listingId": props?.listingId,
    },
  });
};


export const getNFTDetailsList = async (walletAddress) => {
  return axios.post(`/getNFTDetailsList`, { walletAddress });
};

export const getUserDetails = async (walletAddress) => {
  return axios.post(`/getUserDetails`, { walletAddress });
};

export const saveUserDetails = async (data) => {
  return axios.post(`/saveDetails`, data);
};