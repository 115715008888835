import { React, useEffect } from "react";
import "./App.css";
import { Routes, Route } from "react-router-dom";
import routes from "./pages/index";
import { useMetamask } from "use-metamask";
import { ethers } from "ethers";
import Login from "./pages/Login";

const App = () => {
  const { connect, getAccounts, metaState } = useMetamask();

  // instead of calling it from useEffect, you can also call connect method from button click handler
  useEffect(() => {
    const connectAccount = async () => {
      const accounts = await getAccounts();
      if (!metaState.isConnected && accounts.length > 0) {
        (async () => {
          try {
            await connect(ethers.providers.Web3Provider, "any");
          } catch (error) {
            console.log(error);
          }
        })();
      }
    };
    connectAccount();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <Routes>
      {
        metaState.isConnected && metaState.account[0]?.toLocaleLowerCase() === process.env.REACT_APP_ADMIN_ADDRESS?.toLocaleLowerCase()
          && metaState?.chain?.id?.toString() === '5' ?
          routes.map((data, index) => (
            <Route
              onUpdate={() => window.scrollTo(0, 0)}
              exact={true}
              path={data.path}
              element={data.component}
              key={index}
            />
          )) : <Route path="/*" element={<Login />} />}
    </Routes>
  );
};

export default App;
