import React from "react";
import ReactDOM from "react-dom";
import { BrowserRouter } from "react-router-dom";
import { MetamaskStateProvider } from "use-metamask";
import axios from "axios";
import { Provider } from "react-redux";
import { createStore, applyMiddleware } from "redux";
import { persistStore, persistReducer } from "redux-persist";
import storage from "redux-persist/lib/storage"; // defaults to localStorage for web
import { PersistGate } from "redux-persist/integration/react";

import createSagaMiddleware from "redux-saga";

import rootSaga from "./sagas";
import reducers from "./reducers";
import App from "./App";
import ScrollToTop from "./ScrollToTop";
import { requestApi } from "./utils/constant";
import ErrorPopUp from "./components/comman/ErrorPopUp";

axios.defaults.baseURL = requestApi;
const persistConfig = {
  key: "root",
  storage,
};
const rootReducer = reducers;
const persistedReducer = persistReducer(persistConfig, rootReducer);

const sagaMiddleware = createSagaMiddleware();

let store = createStore(persistedReducer, applyMiddleware(sagaMiddleware));
let persistor = persistStore(store);

const rootElement = document.getElementById("root");

sagaMiddleware.run(rootSaga);

ReactDOM.render(
  <Provider store={store}>
    <PersistGate loading={null} persistor={persistor}>
      <MetamaskStateProvider>
        <BrowserRouter>
          <ScrollToTop />
          <App />
          <ErrorPopUp/>
        </BrowserRouter>
      </MetamaskStateProvider>
    </PersistGate>
  </Provider>,
  rootElement
);
