const menus = [
  {
    id: 2,
    name: "Minted NFTs",
    links: "/mintnft-list",
  },
  {
    id: 3,
    name: "Listings",
    links: "/marketplace-list",
  },
  {
    id: 4,
    name: "Create",
    links: "/create-item",
  },
];

export default menus;
