import { takeEvery, call, put, fork } from "redux-saga/effects";
import * as actions from "../actions";
import * as api from "../api";

function* getNFTList() {
  try {
    const result = yield call(api.getNFTList);
    yield put(actions.getNFTListSuccess(result.data.data));
  } catch (error) {
    console.log(error);
    yield put(actions.showError(error.message));
  }
}

function* getMarketPlaceList() {
  try {
    const result = yield call(api.getMarketPlaceList);
    yield put(actions.getMarketplaceListSuccess(result.data.data));
  } catch (error) {
    console.log(error);
    yield put(actions.showError(error.message));
  }
}

function* mintNFT(data) {
  try {
    yield call(api.mintNFT, data.payload);
  } catch (error) {
    console.log(error);
    yield put(actions.showError(error.message));
  }
}

function* createDirectList(data) {
  try {
    yield call(api.createDirectList, data.payload);
  } catch (error) {
    console.log(error);
    yield put(actions.showError(error.message));
  }
}

function* createAuctionList(data) {
  try {
    yield call(api.createAuctionList, data.payload);
  } catch (error) {
    console.log(error);
    yield put(actions.showError(error.message));
  }
}

function* cancelDirectList(data) {
  try {
    yield call(api.cancelDirectListing, data.payload);
  } catch (error) {
    console.log(error);
    yield put(actions.showError(error.message));
  }
}

function* cancelAuctionList(data) {
  try {
    yield call(api.cancelAuctionListing, data.payload);
  } catch (error) {
    console.log(error);
    yield put(actions.showError(error.message));
  }
}

function* watchGetNFTListRequest() {
  yield takeEvery(actions.Types.GET_NFT_LIST, getNFTList);
}

function* watchMintNFT() {
  yield takeEvery(actions.Types.MINT_NFT, mintNFT);
}

function* watchGetMarketList() {
  yield takeEvery(actions.Types.GET_MARKETPLACE_LIST, getMarketPlaceList);
}

function* watchCreateDirectList() {
  yield takeEvery(actions.Types.CREATE_DIRECT_LIST, createDirectList);
}

function* watchCreateAuctionList() {
  yield takeEvery(actions.Types.CREATE_AUCTION_LIST, createAuctionList);
}

function* watchCancelDirectList() {
  yield takeEvery(actions.Types.CANCEL_DIRECT_LIST, cancelDirectList);
}

function* watchCancelAuctionList() {
  yield takeEvery(actions.Types.CANCEL_AUCTION_LIST, cancelAuctionList);
}

function* getHistoryList() {
  try {
    const result = yield call(api.getHistoryList);
    yield put(actions.getHistoryListSuccess(result.data.data));
  } catch (error) {
    console.log(error);
    yield put(actions.showError(error.message));
  }
}

function* watchGetHisotryList() {
  yield takeEvery(actions.Types.GET_NFT_HISTORY_LIST, getHistoryList);
}

const allSagas = [
  fork(watchGetNFTListRequest),
  fork(watchMintNFT),
  fork(watchGetMarketList),
  fork(watchCreateDirectList),
  fork(watchCreateAuctionList),
  fork(watchCancelDirectList),
  fork(watchCancelAuctionList),
  fork(watchGetHisotryList),
];

export default allSagas;
