export const Types = {
  MINT_NFT: "MINT_NFT",
  GET_NFT_LIST: "GET_NFT_LIST",
  GET_NFT_HISTORY_LIST: "GET_NFT_HISTORY_LIST",
  GET_NFT_HISTORY_LIST_SUCCESS:"GET_NFT_HISTORY_LIST_SUCCESS",
  GET_NFT_LIST_SUCCESS: "GET_NFT_LIST_SUCCESS",
  SHOW_ERROR: "SHOW_ERROR",
  SET_NFT_DETAILS: "SET_NFT_DETAILS",
  GET_MARKETPLACE_LIST: "GET_MARKETPLACE_LIST",
  GET_MARKETPLACE_LIST_SUCCESS: "GET_MARKETPLACE_LIST_SUCCESS",
  CREATE_DIRECT_LIST: "CREATE_DIRECT_LIST",
  CREATE_AUCTION_LIST: "CREATE_AUCTION_LIST",
  CANCEL_DIRECT_LIST: "CANCEL_DIRECT_LIST",
  CANCEL_AUCTION_LIST: "CANCEL_AUCTION_LIST",
};

export const getNFTListRequest = () => ({
  type: Types.GET_NFT_LIST,
});

export const mintNFTRequest = (data) => ({
  type: Types.MINT_NFT,
  payload: data,
});

export const getNFTListSuccess = (data) => ({
  type: Types.GET_NFT_LIST_SUCCESS,
  payload: data,
});

export const showError = (err) => ({
  type: Types.SHOW_ERROR,
  error: err,
});

export const setNftDetails = (data) => ({
  type: Types.SET_NFT_DETAILS,
  payload: data,
});

export const getMarketplaceRequest = () => ({
  type: Types.GET_MARKETPLACE_LIST,
});

export const getMarketplaceListSuccess = (data) => ({
  type: Types.GET_MARKETPLACE_LIST_SUCCESS,
  payload: data,
});

export const createDirectList = (data) => ({
  type: Types.CREATE_DIRECT_LIST,
  payload: data,
});

export const createAuctionList = (data) => ({
  type: Types.CREATE_AUCTION_LIST,
  payload: data,
});

export const cancelDirectList = (data) => ({
  type: Types.CANCEL_DIRECT_LIST,
  payload: data,
});

export const cancelAuctionList = (data) => ({
  type: Types.CANCEL_AUCTION_LIST,
  payload: data,
});

export const getHistoryListRequest = () => ({
  type: Types.GET_NFT_HISTORY_LIST,
});

export const getHistoryListSuccess = (data) => ({
  type: Types.GET_NFT_HISTORY_LIST_SUCCESS,
  payload: data,
});