import React from "react";

import Modal from "react-modal";

const ConfirmPopup = ({ showModal, onYes, onNo, modalMessage }) => {
    const customStyles = {
        content: {
            width: "25%",
            minWidth: '450px',
            minHeight: '300px',
            height: "25%",
            borderRadius: "10px",
            top: "50%",
            left: "50%",
            right: "auto",
            bottom: "auto",
            transform: "translate(-50%, -50%)",
            background: "#343444",
            zIndex: '10'
        },
    };

    return (
        <Modal
            ariaHideApp={false}
            isOpen={showModal}
            style={customStyles}
        >
            <div className="modal-content">
                <h5 className="text-center modal-header">{modalMessage}</h5>
                <div className="d-flex">
                <button className="sc-button fl-button pri-3 m-3" onClick={onYes}>
                    <span>Yes</span>
                </button>
                <button className="sc-button fl-button pri-3 m-3" onClick={onNo}>
                    <span>No</span>
                </button>
                </div>
               
            </div>
        </Modal>
    );
};

export default ConfirmPopup;
