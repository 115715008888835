import WalletConnect from "./WalletConnect";
import CreateItem from "./CreateItem";
import Login from "./Login";
import SignUp from "./SignUp";
import MintNftList from "./MintNftList";
import NftDetailsPage from "./NftDetailsPage";
import MarketPlaceList from "./MarketPlaceList";
import OrderDetailsPage from "./OrderDetailsPage";
import UserProfile from "./UserProfile";
import OrderHistory from "./OrderHistory";
import HomePage from "./HomePage"

const routes = [
  { path: "/", component: <HomePage /> },
  { path: "/wallet-connect", component: <WalletConnect /> },
  { path: "/create-item", component: <CreateItem /> },
  { path: "/login", component: <Login /> },
  { path: "/sign-up", component: <SignUp /> },
  { path: "/", component: <MintNftList /> },
  { path: "/mintnft-list", component: <MintNftList /> },
  { path: "/nft-details/:id", component: <NftDetailsPage /> },
  { path: "/marketplace-list", component: <MarketPlaceList /> },
  { path: "/order-details/:id", component: <OrderDetailsPage /> },
  { path: "/user", component: <UserProfile /> },
  { path: "/history", component: <OrderHistory /> },
  { path: "/history/:id", component: <UserProfile /> },
];

export default routes;
