import React, { useState } from "react";
import { connect } from "react-redux";
import { get, startCase } from "lodash";

import { useParams, useNavigate } from "react-router-dom";

import Header from "../components/header/Header";
import Footer from "../components/footer/Footer";
import Popup from "../components/loader/Popup";


import { cancelDirectList, cancelAuctionList } from "../actions";
import ImageVideoPreview from "../components/comman/ImageVideoPreview";
import ConfirmPopup from "../components/comman/ConfirmPopup";

export const OrderDetailsPage = ({
  marketList,
  cancelDirectList,
  cancelAuctionList,
}) => {
  const { id } = useParams();
  const orderDetails = marketList.find((order) => order.id === id);


  const [showModal, setShowModal] = useState(false);
  const [cancelModal, setCancelModal] = useState(false);
  const navigate = useNavigate();


  const totalCopies = parseInt(get(orderDetails, "quantity.hex", "0"), 16);
  const listingType = get(orderDetails, "secondsUntilEnd", false)
    ? "Direct Listing"
    : "Auction";

  const onCancelOrder = async (id) => {
    setCancelModal(false);
    setShowModal(true);
    if (listingType === "Auction") {
      await cancelAuctionList({
        listingId: id,
      });
    } else {
      await cancelDirectList({
        listingId: id,
      });
    }
  };

  function closeModal() {
    setShowModal(false);
    navigate('/');
  }

  return (
    <div className="item-details">
      {showModal && (
        <Popup
          showModal={showModal}
          closeModal={closeModal}
          modalMessage={
            "Canel Order is processing, Kindly check after 1-2 mins"
          }
        />
      )}
      <ConfirmPopup showModal={cancelModal} modalMessage={"Are you want to cancel ?"} onYes={()=>onCancelOrder(get(orderDetails, "id", undefined))} onNo={()=>setCancelModal(false)}/>
      <Header />
      <section className="flat-title-page inner">
        <div className="overlay"></div>
        <div className="themesflat-container">
          <div className="row">
            <div className="col-md-12">
              <div className="page-title-heading mg-bt-12">
                <h1 className="heading text-center">Order Details</h1>
              </div>
            </div>
          </div>
        </div>
      </section>

      <div className="tf-section tf-item-details tf-section-mint-nft-list">
        <div className="themesflat-container">
          <div className="row">
            <div className="col-xl-6 col-md-12">
              <div className="content-left">
                <div className="media">
                  <ImageVideoPreview
                    data={get(orderDetails, "asset", {})}
                    alt="whitelabel"
                  />
                </div>
              </div>
            </div>
            <div className="col-xl-6 col-md-12">
              <div className="content-right">
                <div className="sc-item-details">
                  <div className="d-flex justify-content-between mb-5 align-items-center">
                    <h2 className="style2 mb-0">
                      {startCase(get(orderDetails, "asset.name", ""))}
                    </h2>
                    <button
                      className="sc-button btn-mint-nft fl-button pri-3 m-0"
                      type="button"
                      onClick={() =>
                        setCancelModal(true)
                      }
                    >
                      <span>Cancel Order</span>
                    </button>
                  </div>
                  <div className="client-infor sc-card-product">
                    <div className="meta-info">
                      <div className="author">
                        <div className="info overflow-ellipsis">
                          <span>Seller Address</span>
                          <h6 className="break-word">
                            {get(orderDetails, "sellerAddress", "")}
                          </h6>
                        </div>
                      </div>
                    </div>
                    <div className="meta-info">
                      <div className="author">
                        <div className="info">
                          <span>Create By</span>
                          <h6>Guest User</h6>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div className="client-infor sc-card-product">
                    <div className="meta-info">
                      <div className="author">
                        <div className="info">
                          <span>Properties</span>
                          <h6 className="break-word">
                            {
                            JSON.stringify(
                              get(orderDetails, "asset.attributes", {}),
                              null,
                              4
                            ).replace(/"/g, "")}
                          </h6>
                        </div>
                      </div>
                    </div>
                    <div className="meta-info">
                      <div className="author">
                        <div className="info">
                          <span>Copies</span>
                          <h6>{totalCopies}</h6>
                        </div>
                      </div>
                    </div>
                    <div className="meta-info">
                      <div className="author">
                        <div className="info">
                          <span>
                            {get(orderDetails, "secondsUntilEnd", false)
                              ? "Price"
                              : "Buyout Price"}
                          </span>
                          <h6>
                            {get(
                              orderDetails,
                              "buyoutCurrencyValuePerToken.displayValue",
                              {}
                            )}{" "}
                            {get(
                              orderDetails,
                              "buyoutCurrencyValuePerToken.symbol",
                              {}
                            )}
                          </h6>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div className="client-infor sc-card-product">
                    <div className="meta-info">
                      <div className="author">
                        <div className="info">
                          <span>Listing Type</span>
                          <h6>{listingType}</h6>
                        </div>
                      </div>
                    </div>
                    {!get(orderDetails, "secondsUntilEnd", false) && (
                      <div className="meta-info">
                        <div className="author">
                          <div className="info">
                            <span>Min Bid</span>
                            <h6>
                              {get(
                                orderDetails,
                                "reservePriceCurrencyValuePerToken.displayValue",
                                {}
                              )}{" "}
                              {get(
                                orderDetails,
                                "reservePriceCurrencyValuePerToken.symbol",
                                {}
                              )}
                            </h6>
                          </div>
                        </div>
                      </div>
                    )}
                  </div>
                  <h6>Description</h6>
                  <p>{startCase(get(orderDetails, "asset.description", ""))}</p>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <Footer />
    </div>
  );
};

export default connect(
  ({ data }) => ({
    marketList: data.marketList,
  }),
  { cancelDirectList, cancelAuctionList }
)(OrderDetailsPage);
