import React, { useState } from "react";
import { useNavigate } from "react-router-dom";
import Header from "../components/header/Header";
import Footer from "../components/footer/Footer";
import { useMetamask } from "use-metamask";
import { ethers } from "ethers";
import img1 from "../assets/images/icon/connect-1.png";
import { MESSAGE_EVENTS } from "../components/comman/ErrorPopUp";

const customStyles = {
  error: { color: "red", fontSize: "14px", paddingBottom: "10px" },
};

const Login = (props) => {
  const provider = window.ethereum;
  const [error] = useState(null);
  const navigate = useNavigate();
  const { connect, metaState } = useMetamask();

  const [data] = useState([
    {
      img: img1,
      title: "Meta Mask",
      description: "-",
      walletType: "metamask",
    },
  ]);
  const connectMetamask = async () => {
    try {
      if (metaState.isAvailable) {
        await provider.request({
          method: "wallet_switchEthereumChain",
          params: [{ chainId: "0x5" }],
        });
        await connect(ethers.providers.Web3Provider, "any");
        console.log(metaState.account[0]?.toLocaleLowerCase());
        console.log(process.env.REACT_APP_ADMIN_ADDRESS?.toLocaleLowerCase());
        if (
          metaState.account[0]?.toLocaleLowerCase() !==
          process.env.REACT_APP_ADMIN_ADDRESS?.toLocaleLowerCase()
        ) {
          MESSAGE_EVENTS.ERROR_MESSAGE.emit(
            MESSAGE_EVENTS.ERROR_EVENT,
            "Kindly connect with admin account"
          );
        }
        navigate("/user");
      } else {
        MESSAGE_EVENTS.ERROR_MESSAGE.emit(
          MESSAGE_EVENTS.ERROR_EVENT,
          "Please Kindly install Metamask Chorme extrension"
        );
        window.open("https://metamask.io/download.html", "_blank");
      }
    } catch (error) {
      MESSAGE_EVENTS.ERROR_MESSAGE.emit(
        MESSAGE_EVENTS.ERROR_EVENT,
        error.message
      );
    }
  };

  const connectWallet = (type) => {
    if (type === "metamask") {
      connectMetamask();
    }
  };

  return (
    <div>
      <Header />
      <section className="flat-title-page inner">
        <div className="overlay"></div>
        <div className="themesflat-container">
          <div className="row">
            <div className="col-md-12">
              <div className="page-title-heading mg-bt-12">
                <h3 className="heading text-center">Connect your wallet</h3>
              </div>
            </div>
          </div>
        </div>
      </section>
      <section className="tf-login tf-section">
        <div className="themesflat-container login-container mt-3">
          <div className="row">
            <div className="col-12">
              {/* <h4 className="tf-title-heading ct style-1">
                                Login To NFTs
                            </h4> */}
              <div className="flat-form box-login-email">
                <div className="form-inner">
                  <form>
                    {/* <input id="name" name="name" tabIndex="1" value={userName} aria-required="true" required type="text" placeholder="Username" onChange={(e) => {
                                            setUserName(e.target.value);
                                            onInputChange();
                                        }} /> */}
                    {error && (
                      <div className="form-item" style={customStyles.error}>
                        <span> Error : </span>
                        {error}
                      </div>
                    )}
                    {data.map((item, index) => (
                      <div className="form-item d-flex" key={index}>
                        <button
                          type="button"
                          className="submit"
                          onClick={() => connectWallet(item.walletType)}
                        >
                          <span className="cursor-pointer mr-2">
                            {" "}
                            <img src={item.img} alt="whitelabel" />
                          </span>
                          <span>Connect to {item.title}</span>
                        </button>
                      </div>
                    ))}
                  </form>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
      <Footer />
    </div>
  );
};

export default Login;
