import React, { useEffect, useState } from "react";
import { Link } from "react-router-dom";
import { get } from "lodash";
import { connect } from "react-redux";

import { getNFTListRequest, setNftDetails } from "../actions";

import { formatWalletHash } from "../utils/transformers";
import Header from "../components/header/Header";
import Footer from "../components/footer/Footer";
// import PageLoader from "../components/loader/PageLoader";
import { TailSpin } from  'react-loader-spinner'
import ImageVideoPreview from "../components/comman/ImageVideoPreview";

const MintNftList = ({
  nftListItems,
  getNFTListRequest,
  error,
  loading,
  setNftDetails,
}) => {
  const [copySuccess, setCopySuccess] = useState(false);
  const [searchKey, setSearchKey] = useState("");
  const [displayList, setDisplayList] = useState(nftListItems);

  useEffect(() => {
    getNFTListRequest();
  }, [getNFTListRequest]);

  useEffect(() => {
    if (searchKey === "") setDisplayList(nftListItems);
  }, [nftListItems, searchKey]);

  const [visible, setVisible] = useState(6);
  const showMoreItems = () => {
    setVisible((prevValue) => prevValue + 3);
  };

  const handleSearchInput = (e) => {
    const value = e.target.value.toLowerCase();
    let list = nftListItems.filter((element) =>
      get(element, "metadata.name", "").toLocaleLowerCase().includes(value)
    );
    setSearchKey(value);
    setDisplayList(list);
  };

  return (
    <div>
      <Header />
      <section className="flat-title-page inner">
        <div className="overlay"></div>
        <div className="themesflat-container">
          <div className="row">
            <div className="col-md-12">
              <div className="page-title-heading mg-bt-12">
                <h1 className="heading text-center">Mint Nft List</h1>
              </div>
              <div className="breadcrumbs style2">
                <ul>
                  <li>
                    <Link to="/">Home</Link>
                  </li>
                  <li>Minted NFTs</li>
                </ul>
              </div>
            </div>
          </div>
        </div>
      </section>
      <section className="tf-section tf-rank tf-section-mint-nft-list">
        <div className="container-fluid">
          <div className="d-flex justify-content-end">
            <input
              type="text"
              className="input-width search-bar"
              value={searchKey}
              placeholder="Search By NFT Name"
              onChange={handleSearchInput}
            />
            <button
              className="sc-button fl-button pri-3 ml-2 search-button"
              disabled={!searchKey}
              onClick={() => setSearchKey("")}
            >
              <span>Clear Search</span>
            </button>
          </div>
          <div className="row">
            <div className="col-md-12">
              <div className="table-ranking">
                <div className="flex th-title item nft-list">
                  <div className="column">
                    <h3>ID</h3>
                  </div>
                  <div className="column">
                    <h3>IMAGE</h3>
                  </div>
                  <div className="column">
                    <h3>NAME</h3>
                  </div>
                  <div className="column">
                    <h3>DESCRIPTION</h3>
                  </div>
                  <div className="column">
                    <h3>PROPERTIES</h3>
                  </div>
                  <div className="column">
                    <h3>OWNED BY</h3>
                  </div>
                  <div className="column">
                    <h3>COPIES</h3>
                  </div>
                </div>
                {loading ? (
                  <div className="react-loader">
                  <TailSpin color="#4A3CE5" height={80} width={80}/>
                 </div>
                ) : displayList?.length > 0 ? (
                  displayList.slice(0, visible).map((item, index) => (
                    <Link
                      to={`/nft-details/${parseInt(
                        get(item, "metadata.id.hex"),
                        16
                      )}`}
                      key={index}
                      className="fl-item2 column-padding cursor-pointer"
                    >
                      <div className="item flex">
                        <div className="column">
                          <p className="overflow-ellipsis">
                            {parseInt(get(item, "metadata.id.hex"), 16)}
                          </p>
                        </div>
                        <div className="column">
                          <ImageVideoPreview
                            data={get(item, "metadata", {})}
                            alt="whitelabel"
                          />
                        </div>
                        <div className="column overflow-ellipsis">
                          <span>{get(item, "metadata.name", "")}</span>
                        </div>
                        <div className="column overflow-ellipsis">
                          <span>{get(item, "metadata.description", "")}</span>
                        </div>
                        <div className="column overflow-ellipsis">
                          <pre className="properties">
                            {
                              item?.metadata?.properties ? 
                            JSON.stringify(
                              get(item, "metadata.properties", {}),
                              null,
                              4
                            ):
                            JSON.stringify(
                              get(item, "metadata.attributes", {}),
                              null,
                              4
                            )
                            }
                          </pre>
                        </div>
                        <div className="column">
                          <div className="btn-rounded owenedBy">
                            <i
                              onClick={(e) => {
                                e.preventDefault();

                                navigator.clipboard.writeText(
                                  get(item, "creator", "")
                                );
                                setCopySuccess(index);
                                setTimeout(() => setCopySuccess(-1), 1000);
                              }}
                              className={`fal fa-copy cursor-pointer ${
                                copySuccess === index ? "copy-success" : ""
                              }`}
                            ></i>
                            <span className="copy-Text">
                              {formatWalletHash(get(item, "owner", ""))}
                            </span>
                          </div>
                        </div>
                        <div className="column">
                          <span>
                            {parseInt(get(item, "quantityOwned.hex"), 16)}
                          </span>
                        </div>
                      </div>
                    </Link>
                  ))
                ) : (
                  <h3 className="text-center">No NFT found</h3>
                )}
                {!loading && visible < displayList?.length && (
                  <div className="col-md-12 wrap-inner load-more text-center">
                    <Link
                      to="#"
                      id="load-more"
                      className="sc-button loadmore fl-button pri-3"
                      onClick={showMoreItems}
                    >
                      <span>Load More</span>
                    </Link>
                  </div>
                )}
              </div>
            </div>
          </div>
        </div>
      </section>
      <Footer />
    </div>
  );
};

export default connect(
  ({ data }) => ({
    nftListItems: data.nftListItems,
    loading: data.loading,
    error: data.error,
  }),
  {
    getNFTListRequest,
    setNftDetails,
  }
)(MintNftList);
