import React, { useState } from "react";
import { connect } from "react-redux";
import { get, startCase } from "lodash";

import { Link, useParams } from "react-router-dom";
import { createDirectList, createAuctionList } from "../actions";

import Header from "../components/header/Header";
import Footer from "../components/footer/Footer";
import Popup from "../components/loader/Popup";
import ImageVideoPreview from "../components/comman/ImageVideoPreview";
import { MESSAGE_EVENTS } from "../components/comman/ErrorPopUp";

export const NftDetailsPage = ({
  nftListItems,
  createDirectList,
  createAuctionList,
}) => {
  const { id } = useParams();
  const nftDetails = nftListItems.find(
    (nftItem) => parseInt(get(nftItem, "metadata.id.hex"), 16).toString() === id
  );
  console.log(nftDetails);
  console.log(Number(get(nftDetails, "metadata.id", undefined)?.hex), "🔫");

  const totalCopies = parseInt(get(nftDetails, "quantityOwned.hex", "0"), 16);

  const [showModal, setShowModal] = useState(false);
  const [listingType, setListingType] = useState("None");

  const [formData, setFormData] = useState({
    price: 0.0001,
    quantity: 1,
    floorPrice: 0.000001,
    duration: 86400,
  });

  const onButtonSubmit = async () => {
    try {
      if (Number(formData.quantity) <= totalCopies) {
        let apiData = {
          ...formData,
          tokenId: Number(get(nftDetails, "metadata.id", undefined)?.hex),
        };
        if (listingType === "Direct Listing") await createDirectList(apiData);
        else if (listingType === "Auction") await createAuctionList(apiData);
        setListingType("None");
        setShowModal(true);
        setFormData({
          price: 0.0001,
          quantity: 1,
          floorPrice: 0.000001,
          duration: 86400,
        });
      } else {
        MESSAGE_EVENTS.ERROR_MESSAGE.emit(MESSAGE_EVENTS.ERROR_EVENT,"Quantity is greater than the Total Copies");
      }
    } catch (err) {
      MESSAGE_EVENTS.ERROR_MESSAGE.emit(MESSAGE_EVENTS.ERROR_EVENT,err);
    }
  };

  const handleInputChange = (e, name) => {
    setFormData({
      ...formData,
      [name]: e.target.value,
    });
  };

  function closeModal() {
    setShowModal(false);
  }
  const listingOptions = ["Direct Listing", "Auction"];
  const handleClickListingOption = (currentListingType) => {
    setListingType(currentListingType);
  };

  return (
    <div className="item-details" name="NftDetailsPage">
      {showModal && (
        <Popup
          showModal={showModal}
          closeModal={closeModal}
          modalMessage={
            "Listing is processing, check the NFT in the market place list in 1-2 mins"
          }
        />
      )}
      <Header />
      <section className="flat-title-page inner">
        <div className="overlay"></div>
        <div className="themesflat-container">
          <div className="row">
            <div className="col-md-12">
              <div className="page-title-heading mg-bt-12">
                <h1 className="heading text-center">NFT Details</h1>
              </div>
            </div>
          </div>
        </div>
      </section>
      <div className="tf-section tf-item-details tf-section-mint-nft-list">
        <div className="themesflat-container">
          <div className="row">
            <div className="col-xl-6 col-md-12">
              <div className="content-left">
                <div className="media">
                  <ImageVideoPreview
                    data={get(nftDetails, "metadata", {})}
                    alt="whitelabel"
                  />
                </div>
              </div>
            </div>
            <div className="col-xl-6 col-md-12">
              <div className="content-right">
                <div className="sc-item-details">
                  <h2 className="style2">
                    {startCase(get(nftDetails, "metadata.name", ""))}
                  </h2>
                  <div className="client-infor sc-card-product">
                    <div className="meta-info">
                      <div className="author">
                        <div className="info overflow-ellipsis">
                          <span>Owned By</span>
                          <h6 className="break-word">
                            {get(nftDetails, "owner", "")}
                          </h6>
                        </div>
                      </div>
                    </div>
                    <div className="meta-info">
                      <div className="author">
                        <div className="info">
                          <span>Create By</span>
                          <h6>Guest User</h6>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div className="client-infor sc-card-product">
                    <div className="meta-info">
                      <div className="author">
                        <div className="info">
                          <span>Properties</span>
                          <h6 className="break-word">
                            {
                              nftDetails?.metadata?.properties ?
                              JSON.stringify(
                                get(nftDetails, "metadata.properties", {})
                              ).replace(/"/g, ""):
                              JSON.stringify(
                                get(nftDetails, "metadata.attributes", {})
                              ).replace(/"/g, "")
                            }
                          </h6>
                        </div>
                      </div>
                    </div>
                    <div className="meta-info">
                      <div className="author">
                        <div className="info">
                          <span>Copies</span>
                          <h6>{totalCopies}</h6>
                        </div>
                      </div>
                    </div>
                  </div>
                  <h6>Description</h6>
                  <p>
                    {startCase(get(nftDetails, "metadata.description", ""))}
                  </p>
                  <div className="wrap-box explore-1 flex mg-t-21 mg-bt-40">
                    <div className="seclect-box style-1">
                      <div id="artworks" className="dropdown listTypeDropDown">
                        <Link to="#" className="btn-selector nolink">
                          {listingType === "None"
                            ? "Listing Type"
                            : listingType}
                        </Link>
                        <ul className="listTypeDropDownList">
                          {listingOptions.map((option) => (
                            <li
                              onClick={() => handleClickListingOption(option)}
                              key={Math.random()}
                            >
                              <span>{option}</span>
                            </li>
                          ))}
                        </ul>
                      </div>
                    </div>
                  </div>
                  {listingType === "Direct Listing" ? (
                    <div className="mt-4">
                      <form>
                        <div className="row-form style-3 mb-5 align-items-end">
                          <div className="inner-row-form">
                            <h6 className="pb-4">Price (ETH)</h6>
                            <input
                              type="number"
                              name="price"
                              placeholder="Price"
                              value={formData.price}
                              onChange={(e) => handleInputChange(e, "price")}
                            />
                          </div>
                          <div className="inner-row-form">
                            <h6 className="pb-4">Quantity</h6>
                            <input
                              type="number"
                              name="quantity"
                              placeholder="Quantity"
                              value={formData.quantity}
                              max={totalCopies}
                              onChange={(e) => handleInputChange(e, "quantity")}
                            />
                          </div>
                          <div className="inner-row-form">
                            <button
                              className="sc-button  btn-mint-nft fl-button pri-3 m-0"
                              type="button"
                              onClick={onButtonSubmit}
                            >
                              <span>Create Order</span>
                            </button>
                          </div>
                        </div>
                      </form>
                    </div>
                  ) : listingType === "Auction" ? (
                    <div className="mt-4">
                      <form>
                        <div className="row-form style-3 mb-5 align-items-end justify-content-start">
                          <div className="inner-row-form">
                            <h6 className="pb-4">Buyout Price (ETH)</h6>
                            <input
                              type="number"
                              name="price"
                              placeholder="Price"
                              value={formData.price}
                              onChange={(e) => handleInputChange(e, "price")}
                            />
                          </div>
                          <div className="inner-row-form ml-4">
                            <h6 className="pb-4">Quantity</h6>
                            <input
                              type="number"
                              name="quantity"
                              placeholder="Quantity"
                              value={formData.quantity}
                              max={totalCopies}
                              onChange={(e) => handleInputChange(e, "quantity")}
                            />
                          </div>
                        </div>
                        <div className="row-form style-3 mb-5 align-items-end">
                          <div className="inner-row-form">
                            <h6 className="pb-4">Floor Price (ETH)</h6>
                            <input
                              type="number"
                              name="floorPrice"
                              placeholder="Floor Price"
                              value={formData.floorPrice}
                              onChange={(e) =>
                                handleInputChange(e, "floorPrice")
                              }
                            />
                          </div>
                          <div className="inner-row-form">
                            <h6 className="pb-4">Duration (Seconds)</h6>
                            <input
                              type="number"
                              name="duration"
                              placeholder="Duration"
                              value={formData.duration}
                              max={totalCopies}
                              onChange={(e) => handleInputChange(e, "duration")}
                            />
                          </div>
                          <div className="inner-row-form">
                            <button
                              className="sc-button  btn-mint-nft fl-button pri-3 m-0"
                              type="button"
                              onClick={onButtonSubmit}
                            >
                              <span>Create Order</span>
                            </button>
                          </div>
                        </div>
                      </form>
                    </div>
                  ) : null}
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <Footer />
    </div>
  );
};

export default connect(
  ({ data }) => ({
    nftListItems: data.nftListItems,
  }),
  { createDirectList, createAuctionList }
)(NftDetailsPage);
